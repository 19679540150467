import { formatCurrency } from "utils/helpers"

export const ProductCard = ({ product, single = false, onClick, selected = false }) => {
    if (!product) return <></>
    return (
        <div className={`flex items-center justify-center w-full mb-2 py-4 gap-9 `}>
            <div className="flex flex-col items-center w-full h-auto dark:text-white gap-6">
                <div className={`flex justify-between w-full`}>
                    <div className="flex flex-col w-full">
                        <span className="text-lg font-normal">{product.name}</span>
                        {/* <span className="mt-2 text-gray-500">{product.description ? product.description : 'No description'}</span> */}
                    </div>

                    <div className="flex w-full justify-end items-start">
                        <div className="flex flex-col">

                            <span className="text-xl font-normal">
                                {/* round to 2 decimal places */}
                                {formatCurrency(product.price)}
                            </span>
                            {/* <span className=" text-black dark:text-gray-200 text-xs">or £{product.monthly} per month</span> */}
                        </div>
                        {!single && (
                            <div className="ml-5 flex flex-col">
                                <button
                                    onClick={() => onClick(product.id)}
                                    className={` ${selected ? "bg-custom-300" : "bg-custom-100 hover:bg-custom-300"} text-xs text-white font-bold py-2 px-4 rounded-full`}>
                                    {selected ? "Remove" : "Select"}
                                </button>
                                {/* learn more */}
                                {/* <span className="text-xs text-gray-300 mt-2 cursor-pointer text-center">Learn more</span> */}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex w-full gap-8 items-center  text-black dark:text-gray-300">
                    <span className="text-md">
                        <i className="fas fa-coins mr-2 dark:text-white font-bold"></i>
                        Excess: <span className="dark:text-white ml-2 font-bold">{formatCurrency(product.excess)}</span></span>
                    <span className="text-md">
                        <i className="fas fa-money-bill mr-2 dark:text-white font-bold"></i>
                        Payment: <span className="dark:text-white ml-2 font-bold">{formatCurrency(product.price)}</span></span>
                    <span className="text-md">
                        <i className="fas fa-calendar mr-2 dark:text-white font-bold"></i>
                        Duration: <span className="dark:text-white ml-2 font-bold">{product.duration_months ? `${product.duration_months} Months` : 'N\\A'}</span></span>
                </div>


            </div>
        </div>
    )
}