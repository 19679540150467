import { Button, Dialog } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { apiWarrantyPaymentIntent } from 'services/LoveWarrantyServices'
import StripePaymentElement from 'views/stripe-payment'
import { popNotification } from '.'
import { HiCurrencyPound } from 'react-icons/hi'
import { apiGetCustomerSavedCards, apiGetMe, apiUpdatePaymentMethodTo, apiUpdateWarrantyToDirectDebit } from 'services/AuthService'
import classNames from 'classnames'
import isLastChild from 'utils/isLastChild'
import { endOfMonth, getDirectDebitSchedule } from 'views/project/ScrumBoard/utils'
import { useSelector } from 'react-redux'

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]

const PaymentModal = ({ warranty, buttonText = 'Pay Now', buttonOnClick = async () => { }, onComplete = () => { } }) => {
    const [dialogIsOpen, setIsOpen] = useState(false)
    const [paymentIntent, setPaymentIntent] = useState(null)
    const [cards, setCards] = React.useState([])
    const [selectedCard, setSelectedCard] = React.useState(null)
    const [isDirectDebit, setIsDirectDebit] = React.useState(warranty.payment_method === 'direct_debit')
    const { activeDealer, dealerList } = useSelector((state) => state.dealersList.data)
    
    const [directDebitData, setDirectDebitData] = useState({
        schedule: null,
        setup: false,
        payment: {
            date: null,
            paid_at: null,
            paid: false,
            failed: false
        }
    })

    const id = warranty.id

    const isDealerActive = async () => {
        try {
            const resp = await apiGetMe()
            if (resp.data) {
                const data = resp.data
                const directDebitPayment = warranty.direct_debit_payment || {}

                if (data?.level && data.level === 'admin') {
                    if (activeDealer && activeDealer.value) {
                        setDirectDebitData({
                            schedule: dealerList.find(dealer => dealer.id === activeDealer.value).direct_debit_schedule,
                            setup: dealerList.find(dealer => dealer.id === activeDealer.value).direct_debit_setup,
                            payment: {
                                ...directDebitPayment
                            }
                        })
                    }
                } else if (data?.level && data.level === 'dealer') {
                    setDirectDebitData({
                        schedule: data?.dealer.direct_debit_schedule || null,
                        setup: data?.dealer.direct_debit_setup || false,
                        payment: {
                            ...directDebitPayment
                        }
                    })
                }
            } else {
                throw new Error('User session error, please login again.')
            }
        } catch (err) {
            popNotification("Warning", err.message, 'warn')
            return false
        }
    }

    useEffect(() => {
        if (dialogIsOpen) {
            isDealerActive()
        }
    }, [dialogIsOpen])


    const getPaymentURL = async () => {
        if (id) {
            try {
                const resp = await apiWarrantyPaymentIntent(id, selectedCard)

                if (resp && resp.status === 201) {
                    if (selectedCard) {
                        const last_four = cards.find(card => card.id === selectedCard).last_four

                        popNotification('Payment Successful', <p>
                            Payment Method: •••• {last_four}<br />
                            {warranty.vehicle.vrn} warranty is active
                        </p>, 'success', 5000)

                        const resp = await apiUpdatePaymentMethodTo(id, 'card')
                        if (resp && resp.status !== 200) {
                            popNotification('Error Updating Payment Method', resp.message)
                        }

                        onDialogClose(true)
                        return
                    }

                    setPaymentIntent(resp.data.secret)
                    setIsOpen(true)
                }
                else popNotification(resp.message)
            } catch (err) {
                popNotification(err.response.data.message)
            }
        } else {
            popNotification('Something went wrong. Please try again')
        }
    }

    const getMaybeSavedCards = async () => {
        try {
            const response = await apiGetCustomerSavedCards(warranty.customer.id)
            if (response && response.status === 200) {
                const cards = response.data
                setCards(cards)
                return cards.length > 0
            }

            return false
        } catch (err) {
            popNotification(err.response.data.message)

            return false
        }
    }

    const updatePaymentMethod = async () => {
        const paymentCollectionDate = getDirectDebitSchedule(directDebitData, true)
        
        if (warranty.payment_method === 'direct_debit') {
            popNotification('Payment Method Updated', `Payment will be collected on ${paymentCollectionDate}`)
            onDialogClose()
            return
        }

        try {
            const resp = await apiUpdateWarrantyToDirectDebit(id)
            if (resp && resp.status === 200) {
                popNotification('Payment Method Updated', `Payment will be collected on ${paymentCollectionDate}`)
                onDialogClose(true)
                return
            }
        } catch (err) {
            popNotification(err.response.data.message)
        }
    }

    const openDialog = async () => {
        // Prop passed down
        await buttonOnClick()

        // Get saved cards
        const hasCards = await getMaybeSavedCards()

        if (hasCards) {
            setIsOpen(true)
            return
        }

        getPaymentURL()
    }

    const renderSavedCards = () => {
        return (
            <div className="rounded-lg border border-gray-200 dark:border-gray-600 w-full">
                {cards?.map((card, index) => (
                    <div
                        key={card.last_four}
                        className={classNames(
                            'flex flex-col md:flex-row lg:flex-row lg:items-center justify-between gap-3 p-4',
                            !isLastChild(cards, index) && 'border-b border-gray-200 dark:border-gray-600',
                            card.id === selectedCard && 'bg-gray-100 dark:bg-gray-800'
                        )}
                    >
                        <div className="flex items-center gap-3">
                            {card.brand === 'visa' && (
                                <img
                                    src="/img/others/img-8.png"
                                    alt="visa"
                                />
                            )}
                            {card.brand === 'mastercard' && (
                                <img
                                    src="/img/others/img-9.png"
                                    alt="master"
                                />
                            )}
                            <div>
                                <div className="flex items-center">
                                    <div className="text-gray-900 dark:text-gray-100 font-semibold">
                                        {warranty.customer.first_name} ••••{' '}
                                        {card.last_four}
                                    </div>

                                </div>
                                <span>
                                    Expires{' '}
                                    {
                                        months[
                                        parseInt(card.expMonth) - 1
                                        ]
                                    }{' '}
                                    {card.exp_year}
                                </span>
                            </div>
                        </div>
                        <div className="flex">
                            <Button
                                className="mr-2 rtl:ml-2 px-3"
                                variant="plain"
                                size="sm"
                                onClick={() =>
                                    popNotification('Not implemented yet')
                                }
                            >
                                Delete
                            </Button>
                            <Button
                                size="sm"
                                onClick={() => {
                                    setSelectedCard(selectedCard === card.id ? null : card.id)
                                    setIsDirectDebit(false)
                                }}
                            >
                                {card.id === selectedCard ? 'Unselect' : 'Select'}
                            </Button>
                        </div>
                    </div>
                ))}

                <div
                    className={classNames(
                        'border-b border-gray-200 dark:border-gray-600'
                    )}
                />
                <span
                    className="cursor-pointer hover:text-white !px-4 !py-2 font-semibold mx-auto flex justify-center items-center my-3"
                    size="sm"
                    type="button"
                    onClick={() => {
                        getPaymentURL()
                    }}
                >
                    <i className="fas fa-plus mr-1.5"></i> Use new card
                </span>
            </div>
        )
    }

    const onDialogClose = (runCallBk = false) => {
        if (runCallBk) {
            onComplete && onComplete()
        }

        setIsOpen(false)
    }


    return (
        <div>
            <Button
                className="!bg-slate-500 !px-4 !py-2"
                size="sm"
                type="button"
                onClick={openDialog}
            >
                {buttonText}
            </Button>
            <Dialog
                dialogClass="dark:bg-custom-900"
                isOpen={dialogIsOpen}
                onClose={() => onDialogClose()}
                onRequestClose={() => onDialogClose()}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
                width={670}
                fullScreen={false}
                dialogTitle={<h3 className="dialog-title flex gap-2 items-center mb-3"><HiCurrencyPound /> Payment</h3>}
            >
                {directDebitData.setup && (
                    <div className="rounded-lg border border-gray-200 dark:border-gray-600 w-full my-5">
                        <div
                            className={classNames(
                                'flex flex-col md:flex-row lg:flex-row lg:items-center justify-between gap-3 p-4',
                                'border-b border-gray-200 dark:border-gray-600',
                                isDirectDebit && 'bg-gray-100 dark:bg-gray-800'
                            )}
                        >
                            <div className="flex items-center gap-3">
                                <div>
                                    <div className="flex items-center">
                                        <div className="text-gray-900 dark:text-gray-100 font-semibold">
                                            Pay on Account
                                        </div>

                                    </div>
                                    <span>
                                        {getDirectDebitSchedule(directDebitData)}
                                    </span>
                                </div>
                            </div>
                            <div className="flex">
                                <Button
                                    size="sm"
                                    onClick={() => {
                                        setIsDirectDebit(!isDirectDebit)
                                        setSelectedCard(null)
                                    }}
                                >
                                    {isDirectDebit ? 'Unselect' : 'Select'}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {(cards.length > 0 && paymentIntent === null) && renderSavedCards()}

                {(paymentIntent && !isDirectDebit) && (
                    <StripePaymentElement
                        payment_intent={paymentIntent}
                        return_url={`${window.location.href}`}
                    />
                )}

                {((paymentIntent === null) && (selectedCard) || (selectedCard === null && isDirectDebit === true)) && (
                    <div className="flex justify-end gap-2 mt-4">
                        <Button
                            className="!bg-slate-500 !px-4 !py-2"
                            size="sm"
                            type="button"
                            onClick={() => {
                                if (selectedCard) {
                                    // temp
                                    // popNotification('Saved Payment', `Payment Method ID: ${selectedCard}\nCustomer ID: ${warranty.customer.first_name} (${warranty.customer.stripe_customer_id})`)
                                    getPaymentURL()
                                } else if (isDirectDebit) {
                                    updatePaymentMethod()
                                }
                            }}
                        >
                            Pay Now
                        </Button>
                    </div>
                )}
            </Dialog>
        </div>
    )
}

// memoize
export default React.memo(PaymentModal)