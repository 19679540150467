const appConfig = {
    apiPrefix: 'https://api.lovewarranty.co.uk',
    stagingApiPrefix: 'https://api.lovewarranty-staging.co.uk',
    authenticatedEntryPath: '/app/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
