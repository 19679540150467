import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiGetMe, apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess, setIntercomSettings } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

import hex from 'crypto-js/enc-hex'
import hmac from 'crypto-js/hmac-sha256'

const getUserInfo = async (dispatch) => {
    try {
        const resp = await apiGetMe()
        if (resp.data) {
            const secretKey = process.env.REACT_APP_INTERCOM_SECRET_KEY
            const userIdentifier = resp.data.id.toString() // a UUID to identify your user

            const hash = hmac(userIdentifier, secretKey).toString(hex);

            const interComSettings = {
                api_base: "https://api-iam.intercom.io",
                app_id: 'bijxuqd5',
                email: resp.data.email,
                created_at: resp.data.created_at,
                name: resp.data.first_name + ' ' + resp.data.last_name,
                user_id: resp.data.id,
                user_hash: hash
            }

            window.Intercom('boot', interComSettings)
            window.Intercom('update')

            let USER_INFO = {
                avatar: '',
                authority: [resp.data.level || 'USER'],
                ...resp.data
            }

            dispatch(setUser(USER_INFO))
            dispatch(setIntercomSettings(interComSettings))
        }
    } catch (errors) {
        console.log(errors)
    }
}

// Function to set a cookie
// lvCTkn
export function setCookie(value, days, name = 'authorization') {
    let domain = '.lovewarranty.co.uk' // Change to your parent domain
    process.env.NODE_ENV === 'development' ? domain = 'localhost' : domain = '.lovewarranty.co.uk' // Change to your parent domain
    const secure = true // Set to true if using HTTPS

    const expires = new Date()
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000))

    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; domain=${domain}; path=/;${secure ? ' secure' : ''}`
}

// Function to remove a cookie
export function removeCookie(name = 'authorization') {
    let domain = '.lovewarranty.co.uk' // Change to your parent domain
    process.env.NODE_ENV === 'development' ? domain = 'localhost' : domain = '.lovewarranty.co.uk' // Change to your parent domain
    const secure = true // Set to true if using HTTPS

    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;${secure ? ' secure' : ''}`
}


function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { api_key } = resp.data
                dispatch(onSignInSuccess(api_key))

                // ME endpoint
                getUserInfo(dispatch)

                const redirectUrl = query.get(REDIRECT_URL_KEY)

                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )

                setCookie(api_key, 7) // Set cookie to be accessible for 7 days

                return {
                    status: 'success',
                    message: 'Successfully logged in',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))


                // ME endpoint
                getUserInfo(dispatch)

                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = async () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        removeCookie() // Remove cookie
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        try {
            await apiSignOut()
            handleSignOut()
        }
        catch (errors) {
            alert(errors?.response?.data?.message || errors.toString())
            handleSignOut()
        }
    }

    return {
        authenticated: token && signedIn,
        token: token,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
