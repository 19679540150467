import React from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import CloseButton from '../CloseButton'
import { motion } from 'framer-motion'
import { theme } from 'twin.macro'
import useWindowSize from '../hooks/useWindowSize'


const Dialog = (props) => {
    const currentSize = useWindowSize()

    const {
        closeBtnContent,
        children,
        className,
        closable,
        width,
        height,
        fullScreen,
        style,
        isOpen,
        onClose,
        bodyOpenClassName,
        portalClassName,
        overlayClassName,
        contentClassName,
        closeTimeoutMS,
        dialogTitle,
        ...rest
    } = props

    const onCloseClick = (e) => {
        onClose(e)
    }

    const renderCloseButton = (
        <CloseButton
            onClick={onCloseClick}
            className="ltr:right-6 rtl:left-6"
            absolute
            content={closeBtnContent}
        />
    )

    const contentStyle = {
        content: {
            inset: 'unset',
        },
        ...style,
    }

    if (width !== undefined) {
        contentStyle.content.width = width

        if (
            currentSize.width <=
            parseInt(theme`screens.sm`.split(/ /)[0].replace(/[^\d]/g, ''))
        ) {
            contentStyle.content.width = 'auto'
        }
    }
    if (height !== undefined) {
        contentStyle.content.height = height
    }

    const defaultDialogContentClass = 'dialog-content'

    const dialogClass = classNames(defaultDialogContentClass, contentClassName)

    return (
        <Modal
            className={{
                base: classNames('dialog', className),
                afterOpen: 'dialog-after-open',
                beforeClose: 'dialog-before-close',
            }}
            overlayClassName={{
                base: classNames(`${!fullScreen ? 'dialog-overlay' : 'dialog-overlay-full'} ${overlayClassName}`),
                afterOpen: 'dialog-overlay-after-open',
                beforeClose: 'dialog-overlay-before-close',
            }}
            portalClassName={classNames('dialog-portal', portalClassName)}
            bodyOpenClassName={classNames('dialog-open', bodyOpenClassName)}
            ariaHideApp={false}
            isOpen={isOpen}
            style={{ ...contentStyle }}
            closeTimeoutMS={closeTimeoutMS}
            {...rest}
        >
            <motion.div
                className={`${dialogClass} dark:bg-custom-900`}
                initial={{ transform: 'scale(0.9)' }}
                animate={{
                    transform: isOpen ? 'scale(1)' : 'scale(0.9)',
                }}
            >
                <div className="flex flex-row justify-between items-center w-full">
                    {dialogTitle && (<h3 className="dialog-title">{dialogTitle}</h3>)}
                    {closable && renderCloseButton}
                </div>
                {children}
            </motion.div>
        </Modal>
    )
}

Dialog.propTypes = {
    closable: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    overlayClassName: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClose: PropTypes.func,
    portalClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    closeTimeoutMS: PropTypes.number,
    bodyOpenClassName: PropTypes.string,
    dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
    fullScreen: PropTypes.bool,
}

Dialog.defaultProps = {
    closable: true,
    width: 520,
    closeTimeoutMS: 150,
    dialogTitle: '',
    fullScreen: true,
}

export default Dialog
