export const BASIC_FORM_INPUT = [
    {
        label: 'First name:',
        name: 'first_name',
        placeholder: 'Enter first name',
    },
    {
        label: 'Last name:',
        name: 'last_name',
        placeholder: 'Enter last name',
    },
    {
        label: 'Company:',
        name: 'company_name',
        placeholder: 'Enter company name',
    }, {
        label: 'Phone:',
        name: 'phone',
        placeholder: 'Enter phone number',
    },
    {
        label: 'Email:',
        name: 'email',
        placeholder: 'Enter email address',

    },
    {
        label: "Confirm email:",
        name: "confirm_email",
        placeholder: "Enter email address",
    },
    {
        label: 'Postcode:',
        name: 'postcode',
        placeholder: 'Enter postcode',
    }
]

export const ADDRESS_FORM_INPUT = [
    {
        label: 'Address 1:',
        name: 'address_line_1',
        placeholder: 'Enter address line 1',
        value: '',
    },
    {
        label: 'Address 2:',
        name: 'address_line_2',
        placeholder: 'Enter address line 2',
        value: '',
    },
    {
        label: 'City/Town:',
        name: 'town',
        placeholder: 'Enter town',
        value: '',
    },
    {
        label: 'County:',
        name: 'county',
        placeholder: 'Enter county',
        value: '',
    }
]

export const options = [
    { value: 'Website', label: 'Website' },
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Word of Mouth', label: 'Word of Mouth' },
    { value: 'Other', label: 'Other' },
]

export const titleOptions = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Miss', label: 'Miss' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Dr', label: 'Dr' },
    { value: 'Rev', label: 'Rev' },
    { value: 'Other', label: 'Other' },
]


export const ownerInfoKeys = {
    title: 'Title',
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    confirmEmail: 'Confirm email',
    post_code: 'Postcode',
    address_1: 'Address 1',
    address_2: 'Address 2',
    city: 'City',
    county: 'County',
    hearAboutUs: 'How did you hear about us?',
    company: 'Company name',
    phone: 'Phone number'
}

export const vehicleInfoKeys = {
    vrn: 'Registration',
    make: 'Make',
    model: 'Model',
    fuel_type: 'Fuel type',
    transmission: 'Transmission',
    engine_size: 'Engine size',
    body_type: 'Body type',
    colour: 'Colour',
    first_registered: 'First registered',
}


export const common_awnser = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {
        label: 'No',
        value: 'no',
    },
]

export const QUESTIONS = [
    {
        question: 'Is your vehicle used for private hire or commercial use?',
        answers: common_awnser,
        name: 'private_hire',
        expected: "no",
    },
    {
        question: "Are you the owner and responsible for the cost of repairs to your vehicle?",
        answers: common_awnser,
        name: 'owner',
        expected: "yes",
    },
    {
        question: "Do you service your vehicle in accordance with the manufacturer's recommendations?",
        answers: common_awnser,
        name: 'service',
        expected: "yes",
    },
    {
        question: "Have you modified your vehicle in any way from the manufacturer's specification?",
        answers: common_awnser,
        name: 'modified',
        expected: "no",
    },
]

export const DEMO_POLICY_INFO = [
    {
        label: 'Love Warranty Quote ID:',
        value: '123456789'
    }, {
        label: 'Coverage:',
        value: 'Part cover'
    }, {
        label: 'Excess:',
        value: '£250'
    }, {
        label: 'Policy start date:',
        value: '1st January 2021'
    }, {
        label: 'Expiration date:',
        value: '1st January 2022'
    }, {
        label: 'Expiration Mileage:',
        value: '100,000 Miles'
    },
    {

    }
]

export const DEMO_VEHICLE_INFO = [
    {
        label: 'Make:',
        value: 'Volkswagen'
    }, {
        label: 'Model:',
        value: 'Golf'
    }, {
        label: 'Reg:',
        value: 'AB12 CDE'
    }, {
        label: 'Mileage:',
        value: '2,106 Miles'
    }, {
        label: 'Fuel type:',
        value: 'Petrol'
    }
]
