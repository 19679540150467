import { createSlice } from '@reduxjs/toolkit'
import { initialOwnerInfo, initialWarrantyData, intialQualifyingInfo } from '../constants/intialValues';

const dataSlice = createSlice({
    name: 'createWarranty/data',
    initialState: {
        productData: null,
        warrantyData: initialWarrantyData,
        ownerInfo: initialOwnerInfo,
        vehicleInfo: null,
        warranty: null,
        qualification: intialQualifyingInfo,
        searchUser: {
            searched: false,
            found: false
        }
    },
    reducers: {
        setProduct: (state, action) => {
            state.productData = action.payload
        },
        setWarrantyData: (state, action) => {
            state.warrantyData = action.payload
        },
        setOwnerInfo: (state, action) => {
            state.ownerInfo = action.payload
        },
        setVehicleInfo: (state, action) => {
            state.vehicleInfo = action.payload
        },
        discardWarrantyData: (state) => {
            // if reg is in url, then remove it
            const url = new URL(window.location.href)
            if (url.searchParams.get('reg')) {
                url.searchParams.delete('reg')
                window.history.replaceState({}, '', url)
                // refresh page
                window.location.reload()
            }

            state.productData = null
            state.warrantyData = initialWarrantyData
            state.ownerInfo = {}
            state.vehicleInfo = null
            state.qualification = intialQualifyingInfo
            state.searchUser = {
                searched: false,
                found: false
            }
        },
        setWarranty: (state, action) => {
            state.warranty = action.payload
        },
        setSearchedUser: (state, action) => {
            state.searchUser = action.payload
        },
        setQualification: (state, action) => {
            state.qualification = action.payload
        }
    }
})

export const {
    setProduct,
    setWarrantyData,
    setOwnerInfo,
    setVehicleInfo,
    discardWarrantyData,
    setWarranty,
    setSearchedUser,
    setQualification
} = dataSlice.actions


export default dataSlice.reducer
