export const convertNumbThousand = (x, shrink = false) => {
    if (!x) {
        return "0";
    }

    if (shrink) {
        if (x >= 1000000000) {
            return `${(x / 1000000000).toFixed(1)}B`;
        }
        if (x >= 1000000) {
            return `${(x / 1000000).toFixed(1)}M`;
        }
        if (x >= 1000) {
            return `${(x / 1000).toFixed(1)}K`;
        }
    }

    return x.toLocaleString("en-GB");
};