import { Button, DatePicker, FormContainer, FormItem, Input, Radio } from 'components/ui';
import React, { useState } from 'react'
import { QUESTIONS } from '../constants/steps';
import { getYear } from 'utils/helpers';
import dayjs from 'dayjs'

import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { discardWarrantyData, setWarrantyData } from '../store/dataSlice';

const validationSchema = Yup.object().shape({
    milage: Yup.number().required("Mileage is a required").min(1, "Mileage must be greater than 0"),
    valid_from: Yup.date().required("Date is required"),
    vehicle_value: Yup.number().required("Vehicle value is required").min(0, "Vehicle value must be greater than 0"),
})

export const VehicleInfo = ({ setStep }) => {
    const dispatch = useDispatch()
    const { warrantyData, vehicleInfo } = useSelector((state) => state.createWarranty.data)

    const [failed, setFailed] = useState({
        disabled: false,
        message: "Please answer all questions"
    })

    const [qualification, setQualification] = useState(QUESTIONS.map((question) => ({
        question: question.question,
        answer: null,
        expected: question.expected,
    })))

    const formButtonRef = React.useRef()

    const NumberFormatInput = ({ onValueChange, ...rest }) => {
        return (
            <NumberFormat
                customInput={Input}
                type="text"
                onValueChange={onValueChange}
                autoComplete="off"
                {...rest}
            />
        )
    }

    const NumberInput = (props) => {
        return <Input {...props} value={props.field.value} />
    }

    const onCheck = (question, anwser) => {
        let checks = qualification.map((item) => {
            if (item.question === question) {
                item.answer = anwser

                if (item.expected !== anwser) return false
                else setFailed({
                    disabled: false,
                    message: "Please answer all questions"
                })
                return true
            } else {
                if (item.expected !== item.answer) return false
                else return true
            }
        })

        setQualification(qualification)

        if (checks.includes(false)) {
            setFailed({
                disabled: true,
                message: "You do not qualify for this warranty, please contact us for more information"
            })
        }
    }

    const isChecked = (question, answer) => {
        let checked = qualification.map((item) => {
            if (item.question === question && item.answer === answer) {
                return true
            }
        })

        return checked.includes(true)
    }

    const renderCarDetails = () => {
        return (
            <div className="space-y-5 mt-6 w-full">
                <div className="grid grid-cols-1 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 w-full lg:w-fit">
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3">
                        <i className="fal fa-calendar text-xl"></i>
                        <div className="grid grid-cols-3 text-black dark:text-white font-normal w-full">
                            <span className="opacity-60 ">Registration year </span>
                            <span></span>
                            <span>{vehicleInfo.first_registered ? `${getYear(vehicleInfo.first_registered)}` : "N/A"}</span>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="fal fa-cog text-xl"></i>
                        <div className="grid grid-cols-3 text-black dark:text-white font-normal w-full">
                            <span className="opacity-60 ">Transmission </span>
                            <span></span>
                            <span>{vehicleInfo.transmission ? `${vehicleInfo.transmission}` : "N/A"}</span>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="fa-light fa-car-wrench text-xl"></i>
                        <div className="grid grid-cols-3 text-black dark:text-white font-normal w-full">
                            <span className="opacity-60 ">Body type </span>
                            <span></span>
                            <span>{vehicleInfo.body_type ? `${vehicleInfo.body_type}` : "N/A"}</span>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="fal fa-engine text-xl"></i>
                        <div className="grid grid-cols-3 text-black dark:text-white font-normal w-full">
                            <span className="opacity-60 ">Engine </span>
                            <span></span>
                            <span>{vehicleInfo.engine_size ? `${vehicleInfo.engine_size}` : "N/A"}</span>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="fat fa-gas-pump text-xl"></i>
                        <div className="grid grid-cols-3 text-black dark:text-white font-normal w-full">
                            <span className="opacity-60 ">Fuel Type </span>
                            <span></span>
                            <span>{vehicleInfo.fuel_type ? `${vehicleInfo.fuel_type}` : "N/A"}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const nextClick = () => formButtonRef.current.click()

    const renderCarForm = (initialValues) => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={
                    (values, { setSubmitting }) => {
                        let isFailed = qualification.map((item) => {
                            if (item.answer === null) return true
                        })

                        if (isFailed.includes(true)) {
                            setFailed({
                                disabled: true,
                                message: "Please answer all questions"
                            })

                            return
                        }

                        const formattedDate = dayjs(values.valid_from).format("YYYY-MM-DD")

                        dispatch(setWarrantyData({
                            ...warrantyData,
                            milage: parseInt(values.milage),
                            vehicle_value: parseFloat(values.vehicle_value),
                            valid_from: formattedDate
                        }))

                        setTimeout(() => {
                            setStep(1)
                        }, 500);
                    }
                }
            >
                {({ values, touched, errors, isSubmitting }) => {
                    return (
                        <Form className="w-full mt-6">
                            <FormContainer>
                                <FormItem
                                    invalid={errors.milage && touched.milage}
                                    errorMessage={errors.milage}
                                    labelClass="w-full"
                                    className="w-full gap-4"
                                    label={<span className="flex gap-3 items-center"><i className="fa-sharp fa-light fa-road text-xl"></i> Current Mileage</span>}
                                >
                                    <Field name="milage">
                                        {({ field, form }) => {
                                            return (
                                                <NumberFormatInput
                                                    className=" w-full rounded-full"
                                                    form={form}
                                                    field={field}
                                                    customInput={NumberInput}
                                                    placeholder="Mileage"
                                                    onValueChange={(e) => {
                                                        form.setFieldValue(
                                                            field.name,
                                                            e.value
                                                        )
                                                    }}
                                                />
                                            )
                                        }}
                                    </Field>
                                </FormItem>

                                <FormItem
                                    className="w-full flex gap-4"
                                    invalid={errors.valid_from && touched.valid_from}
                                    errorMessage={errors.valid_from}
                                    labelClass="w-full"
                                    label={<span className="flex items-center gap-3 w-full lg:w-fit"><i className="fa-sharp fa-light fa-calendar text-xl"></i> Warranty Start Date</span>}
                                >
                                    <Field
                                        name="valid_from"
                                        label="Pick a date"
                                        placeholder="Pick a date">
                                        {({ field, form }) => {
                                            return <DatePicker
                                                field={field}
                                                className="w-full rounded-full"
                                                form={form}
                                                defaultValue={new Date(field.value)}
                                                value={new Date(field.value)}
                                                onChange={(date) => {
                                                    form.setFieldValue(
                                                        field.name,
                                                        date
                                                    )
                                                }}
                                            />
                                        }}
                                    </Field>
                                </FormItem>

                                <FormItem
                                    invalid={errors.vehicle_value && touched.vehicle_value}
                                    errorMessage={errors.vehicle_value}
                                    className="w-full flex gap-4"
                                    labelClass="w-full"
                                    label={<span className="flex gap-3 items-center w-full lg:w-fit"><i class="text-xl fa-solid fa-circle-sterling"></i> Current vehicle value</span>}
                                >
                                    <Field name="vehicle_value">
                                        {({ field, form }) => {
                                            return (
                                                <NumberFormatInput
                                                    className="col-span-2 w-full rounded-full"
                                                    form={form}
                                                    field={field}
                                                    customInput={NumberInput}
                                                    placeholder="Vehicle value"
                                                    onValueChange={(e) => {
                                                        form.setFieldValue(
                                                            field.name,
                                                            e.value
                                                        )
                                                    }}
                                                />
                                            )
                                        }}
                                    </Field>
                                </FormItem>

                                <Button
                                    variant="solid"
                                    type="submit"
                                    className="hidden"
                                    ref={formButtonRef}
                                >
                                    Save
                                </Button>
                            </FormContainer>
                        </Form>
                    )
                }}
            </Formik>
        )
    }

    return (
        <div className="mx-auto flex flex-col gap-9 w-full px-4">
            <div className='mx-auto flex flex-col lg:flex-row w-full items-center gap-3'>
                <div className="relative text-neutral-900 dark:text-white flex flex-col items-start lg:h-full w-full lg:mt-0">
                    <h2 className="text-xl font-semibold text-left max-w-md">
                        Vehicle Information
                    </h2>
                    <span className="text-md mt-2 text-left dark:text-white font-normal mb-4">
                        Let's make sure we have the correct details
                    </span>
                    <div className="relative z-10 flex space-y-3 justify-between w-full">
                        <div className='flex flex-col'>
                            <h2 className="text-xl font-semibold text-left max-w-md">
                                {vehicleInfo.make ? vehicleInfo.make : "N/A"} {vehicleInfo.model ? vehicleInfo.model : "N/A"}
                            </h2>
                            <span className="text-md mt-2 text-left dark:text-white font-normal">
                                Reg: {vehicleInfo.vrn ? vehicleInfo.vrn : "N/A"}
                            </span>
                        </div>

                        <span className="mt-4 text-left dark:text-white font-medium">
                            Not your vehicle?
                            <button
                                className='ml-1 text-custom-300 hover:cursor-pointer text-sm'
                                onClick={() => dispatch(discardWarrantyData())}
                            >
                                search again
                            </button>
                        </span>

                    </div>


                    {warrantyData && renderCarForm({
                        milage: warrantyData.milage ? warrantyData.milage : '',
                        vehicle_value: warrantyData.vehicle_value ? warrantyData.vehicle_value : '',
                        valid_from: warrantyData.valid_from ? warrantyData.valid_from : new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                    })}


                    <div className="mt-4 text-right mb-8 w-full">
                        <h2 className="text-xl font-semibold text-left max-w-md my-3">
                            Details
                        </h2>

                        {renderCarDetails()}
                    </div>

                    <div className="mt-4 text-center lg:text-left">
                        <h2 className="text-xl font-semibold text-left max-w-md my-3">
                            Qualifications
                        </h2>
                        <div className="mt-6 flex flex-col w-full space-y-6">
                            {QUESTIONS.map((question, index) => (
                                <div className="flex items-center justify-between flex-col lg:flex-row text-black dark:text-white gap-3" key={index}>
                                    <span className="">{question.question}</span>
                                    <div className="flex items-center justify-between">
                                        {question.answers.map((answer, index) => (
                                            <Radio
                                                className="mr-4 " name={question.name}
                                                onChange={() => onCheck(question.question, answer.value)}
                                                key={index}
                                                defaultChecked={isChecked(question.question, answer.value)}
                                            >
                                                {answer.label}
                                            </Radio>
                                        ))}
                                    </div>
                                </div>
                            ))}

                            {/* notice if failed is true */}
                            {failed.disabled && (
                                <div className="flex items-center justify-between flex-col lg:flex-row text-black dark:text-white">
                                    <span className="text-red-500">{failed.message}</span>
                                </div>
                            )}
                        </div>

                    </div>
                </div>

            </div>
            {/* car details */}


            <div className="mt-4 text-right">
                <Button
                    onClick={nextClick}
                    disabled={failed.disabled}
                    variant="solid">Next</Button>
            </div>
        </div>
    );
}