import React, { useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, Notification, toast } from 'components/ui'

export const StripePaymentForm = ({
    return_url = `${window.location.origin}/completion`
}) => {
    const stripe = useStripe()
    const elements = useElements()

    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            openNotification("Stripe Error", "Stripe.js has not yet loaded", 'danger')
            return
        }

        setIsProcessing(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: return_url,
            },
        })

        if (error.type === "card_error" || error.type === "validation_error") {
            // setMessage(error.message);
            let title = error.type === "card_error" ? "Card Error" : "Validation Error"
            openNotification(title, error.message, 'danger')
        } else {
            openNotification("Payment Success", "Payment Success", 'success')
        }

        setIsProcessing(false)
    }


    const openNotification = (title, message, type = 'danger') => {
        toast.push(
            <Notification
                title={title}
                type={type}
            >
                {message}
            </Notification>, {
            placement: "top-center"
        }
        )
    }


    return (
        <form onSubmit={handleSubmit} className="w-full text-white dark:text-white">
            <PaymentElement className="text-white" />

            <Button
                type="submit"
                disabled={!stripe || isProcessing}
                className="w-full mt-3"
            >
                {isProcessing ? "Processing..." : "Pay"}
            </Button>
        </form>
    )
}
