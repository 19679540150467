import React, { useEffect, useState } from 'react'
import { ProductCard } from '../ProductCard'
import { Button } from 'components/ui'
import { useDispatch, useSelector } from 'react-redux'
import { apiGetProducts } from 'services/LoveWarrantyServices'
import { setProduct, setWarrantyData } from '../store/dataSlice'


const NEXT_STEP = 3

export const Products = ({ setStep }) => {
    const [error, setError] = useState(null)
    const [products, setProducts] = useState(null)

    const dispatch = useDispatch()
    const { warrantyData } = useSelector((state) => state.createWarranty.data)

    const getProducts = async () => {
        try {
            if (!warrantyData.vehicle_id || !warrantyData.valid_from || !warrantyData.vehicle_value || !warrantyData.milage) {
                setError("Please fill all the fields")
            }

            const products = await apiGetProducts({
                milage: warrantyData.milage,
                vehicle_id: warrantyData.vehicle_id,
                valid_from: warrantyData.valid_from,
                vehicle_value: warrantyData.vehicle_value
            })

            if (products && products.data) {
                if (products.data) setProducts(products.data)
            }
        } catch (err) {
            setError(err.response.data.message)
        }
    }


    useEffect(() => {
        getProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warrantyData])

    const handleProductSelect = (productID) => {
        dispatch(setWarrantyData({
            ...warrantyData,
            product_id: productID
        }))
        dispatch(setProduct(products.data.find(item => item.id === productID)))
        setStep(NEXT_STEP)
    }

    return (
        <div className='flex text-left flex-col w-full mx-auto px-6 h-full'>
            <h2 className="text-2xl font-bold">Select a warranty product</h2>
            <span className="text-gray-300/90">Choose one of the products below</span>
            <div className="flex flex-col items-center justify-center w-full mt-8">
                {products?.data && <span className="text-gray-200 text-lg mb-6 text-center font-semibold">There are currently no products available for this vehicle</span>}
                {products && (
                    products.data.map((item, index) => (
                        <ProductCard key={index} product={item} onClick={handleProductSelect} selected={warrantyData?.product_id === item.id} />
                    ))
                )}
            </div>

            <span className="text-gray-300/90 text-xs mt-5">
                *The policy price is based on the mileage, excess and start date of the policy that you have selected
                <br />
                <br />
                At policy take up if any changes are made to the mileage, excess choice or start date then this may affect the cost of your policy price
            </span>

            {error && <pre><br/><span className="text-red-500 text-sm mt-8">Oops, There was an error : {error}</span></pre>}
            <div className="pt-16 text-right w-full flex justify-between">
                <Button
                    className="mx-2"
                    type="button"
                    onClick={() => { setStep(1) }}
                >
                    Previous
                </Button>

                <Button
                    onClick={() => setStep(NEXT_STEP)}
                    disabled={!warrantyData?.product_id}
                    type="submit"
                    variant="solid">
                    Next
                </Button>
            </div>

        </div>
    )
}