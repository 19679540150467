import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { popNotification } from 'views/warranties/CreateWarranty'

const unauthorizedCode = [401]

// Configure API URLs based on environment
const apiPrefix = process.env.REACT_APP_API_DOMAIN || appConfig.apiPrefix

const BaseService = axios.create({
    timeout: 60000,
    baseURL: apiPrefix,
})

const whiteListedEndpoints = [
    'dealers',
    'balances',
    'vehicles',
    'users',
    'products',
    'download_links',
    'payment_intents',
    'warranties_accepted',
    'login',
    'me',
    'stats',
    'password_changes',
    'config',
    'warranties_cancelled'
]

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        let accessToken = persistData.auth.session.token
        const level = persistData.auth.user.authority[0]

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        try {
            if (level && level === 'admin') {
                // Experimental: add Dealer-ID to all requests header
                const dealerId = JSON.parse(localStorage.getItem('activeDealer'))?.value
                config.headers['Dealer-ID'] = dealerId

                // if method is post 
                if (config.method === 'get') {
                    // if url is whitelisted, don't add dealer id
                    let shouldAddDealerId = !whiteListedEndpoints.some((endpoint) => config.url.includes(endpoint))

                    // if url ends with a number, don't add dealer id
                    // const lastPartOfUrl = config.url.split('/').pop()
                    // if (!isNaN(lastPartOfUrl)) shouldAddDealerId = false

                    // if (shouldAddDealerId) {
                    //     // if dealer id already exists in url, don't add it again
                    //     if (!config.url.includes('dealer_id')) {
                    //         config.url = `${config.url}&dealer_id=${dealerId}`
                    //     }
                    // }
                }
            } else {
                // remove dealerId from local storage
                localStorage.removeItem('activeDealer')
            }
        } catch (e) {
            const message = e.message || 'Something went wrong. Please try again.'
            popNotification(message, 2500, 'Error')
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error
        const message = response?.data?.message || 'Something went wrong. Please try again.'
        popNotification(message, 2500, 'Error')

        if (response && unauthorizedCode.includes(response.status)) {
            // store.dispatch(onSignOutSuccess())
            popNotification('You are not authorized to perform that action', 2500, 'Error')
        }

        return Promise.reject(error)
    }
)

export default BaseService
