import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/login',
        method: 'post',
        data,
    })
}

export async function apiChangePassword(data) {
    return ApiService.fetchData({
        url: '/password_changes',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiGetMe() {
    return ApiService.fetchData({
        url: '/me',
        method: 'get',
    })
}


export async function apiSignOut() {
    return ApiService.fetchData({
        url: '/login',
        method: 'delete',
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/password_resets',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data, hash) {
    return ApiService.fetchData({
        url: '/password_resets/' + hash,
        method: 'post',
        data,
    })
}

export async function apiCreateNewPassword({ user_id, hash }, data) {
    return ApiService.fetchData({
        url: `/users/${user_id}/password_creations/${hash}`,
        method: 'post',
        data,
    })
}

export async function apiGetStripePK() {
    return ApiService.fetchData({
        url: '/config',
        method: 'get',
    })
}


export async function apiGetCustomerSavedCards(customerId) {
    return ApiService.fetchData({
        url: `/customers/${customerId}/cards`,
        method: 'get',
    })
}

export async function apiUpdateWarrantyToDirectDebit(warrantyId) {
    return ApiService.fetchData({
        url: `/warranties/${warrantyId}`,
        method: 'put',
        data: {
            payment_method: 'direct_debit'
        }
    })
}

export async function apiUpdatePaymentMethodTo(warrantyId, paymentMethod) {
    return ApiService.fetchData({
        url: `/warranties/${warrantyId}`,
        method: 'put',
        data: {
            payment_method: paymentMethod
        }
    })
}

export async function apiDealerHasDirectDebit(dealerId) {
    return ApiService.fetchData({
        url: `/dealers/${dealerId}/has_direct_debit`,
        method: 'get',
    })
}