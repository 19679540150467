import { QUESTIONS } from "./steps";

export const initialVehicleInfo = {
    yearOfManufacture: "",
    mileage: "",
    fuelType: "",
    transmissionType: "",
    ownershipCondition: "",
    vehicleType: "",
    vehicleMake: "",
    vehicleModel: "",
    registration: "",
}

export const initialOwnerInfo = {
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    post_code: "",
    address_1: "",
    address_2: "",
    city: "",
    county: "",
    hearAboutUs: "",
    phone: "",
    company: "",
    preferPaperCopy: false,
    preferEmailCopy: true,
}

export const intialQualifyingInfo = QUESTIONS.map((question) => ({
    question: question.question,
    answer: null,
    expected: question.expected,
}))

export const initialWarrantyData = {
    valid_from: null,
    milage: null,
    vehicle_value: null,
    vehicle_id: null,
    customer_id: null,
    product_id: null
}