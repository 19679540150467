
import React, { useState } from 'react'
import { Notification, Steps, toast } from 'components/ui'
import { VehicleInfo } from './Steps/VehicleInfo'
import { OwnerInfo } from './Steps/OwnerInfo'
import { Products } from './Steps/Products'
import { Review } from './Steps/Review'
import { Complete } from './Steps/Complete'
import { VehicleSearchForm } from './Steps/VehicleSearchForm'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const popNotification = (message, duration = 5000, title = "Error", type = "danger") => {
    toast.push(
        <Notification
            title={title}
            type={type}
            duration={duration}
        >
            {message}
        </Notification>,
        {
            placement: 'top-center',
        }
    )
}

const CreateWarranty = ({ modalClose, isModal = false }) => {
    const [step, setStep] = useState(0)
    const { vehicleInfo } = useSelector((state) => state.createWarranty.data)

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const regValue = searchParams.get('reg') || ""

    const changeStep = (step) => {
        setStep(step)
        window.scrollTo(0, 0)
    }

    const renderStep = (step) => {
        switch (step) {
            case 0:
                return <VehicleInfo setStep={changeStep} />
            case 1:
                return <OwnerInfo setStep={changeStep} />
            case 2:
                return <Products setStep={changeStep} />
            case 3:
                return <Review setStep={changeStep} />
            case 4:
                return <Complete closeModal={modalClose} isModal={isModal} />
            default:
                return <VehicleSearchForm reg={regValue} />
        }
    }



    const renderStepForm = () => {
        return (
            <>
                <Steps current={step}>
                    <Steps.Item />
                    <Steps.Item />
                    <Steps.Item />
                    <Steps.Item />
                    <Steps.Item />
                </Steps>
                <div className={`mt-6 ${step === 1 ? 'h-full' : 'h-auto  py-8'} bg-gray-50 dark:bg-custom-900 rounded flex items-center justify-center`}>
                    {renderStep(step)}
                </div>
            </>
        )
    }

    return (
        <div className={` w-full mt-2 ${!isModal ? 'pt-6 px-6' : ''} rounded-lg overflow-x-hidden no-scrollbar m-auto h-full dark:bg-custom-900`}>
            {vehicleInfo ? renderStepForm() : <VehicleSearchForm reg={regValue} />}
        </div>
    )
}

export default CreateWarranty

