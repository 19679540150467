export const statusColor = {
    'draft': {
        label: 'Draft',
        dotClass: 'bg-yellow-500',
        textClass: 'text-yellow-500',
    },
    'expired': {
        label: 'Expired',
        dotClass: 'bg-amber-500',
        textClass: 'text-amber-500',
    },
    'paid': {
        label: 'Paid',
        dotClass: 'bg-emerald-500',
        textClass: 'text-emerald-500',
    },
    'unpaid': {
        label: 'Pending Payment',
        dotClass: 'bg-red-500',
        textClass: 'text-red-500',
    },
    'cancelled': {
        label: 'Cancelled',
        dotClass: 'bg-gray-400',
        textClass: 'text-gray-400',
    },
    'refunded': {
        label: 'Refunded',
        dotClass: 'bg-red-400',
        textClass: 'text-gray-400',
    },
    'direct_debit': {
        label: 'Processing Payment',
        dotClass: 'bg-emerald-500',
        textClass: 'text-gray-400',
    },
}

export const warrantyStatus = (policy) => {
    if (policy.refunded) {
        return statusColor['refunded']
    }

    if (policy.cancelled) {
        return statusColor['cancelled']
    }

    if (policy.expired) {
        return statusColor['expired']
    }

    if (policy.draft) {
        return statusColor['draft']
    }

    if (policy.paid) {
        return statusColor['paid']
    }

    if (policy.payment_method === 'direct_debit') {
        return statusColor['direct_debit']
    }

    return statusColor['unpaid']
}