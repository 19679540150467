import React, { useState } from 'react'
import { Button } from 'components/ui'
import { useDispatch, useSelector } from 'react-redux'
import { popNotification } from '..'
import { useNavigate } from 'react-router-dom'
import { discardWarrantyData } from '../store/dataSlice'

// Payment
import { apiAcceptWarranty, apiWarrantyPaymentIntent } from 'services/LoveWarrantyServices'
import StripePaymentElement from 'views/stripe-payment'
import PaymentModal from 'views/warranties/WarrantyActions/PaymentModal'

export const Complete = ({ closeModal, isModal }) => {
    const dispatch = useDispatch()
    const { warranty } = useSelector((state) => state.createWarranty.data)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const [secret, setSecret] = useState(null)

    const getPaymentURL = async () => {
        if (warranty.id) {
            try {
                const resp = await apiWarrantyPaymentIntent(warranty.id)

                if (resp && resp.status === 201) setSecret(resp.data.secret)
                else popNotification(resp.message)

            } catch (err) {
                popNotification(err.response.data.message)
            }
        } else {
            popNotification('Something went wrong. Please try again')
        }
    }

    const handleClick = (payment = null) => {
        if (isModal) closeModal()
        dispatch(discardWarrantyData())
        navigate(`/app/warranties/edit-warranty/${warranty.id}${payment ? `?payment=${payment}` : ''}`)
    }

    const onAccept = async () => {
        setLoading(true)

        try {
            const resp = await apiAcceptWarranty({
                id: parseInt(warranty.id)
            })

            if (resp && resp.status === 201) {
                popNotification("Warranty Accepted", 2000, "Success", "success")

                setTimeout(() => {
                    // getPaymentURL()
                    setLoading(false)
                }, 1000)
            }
            // ALREADY ACCEPTED
            else if (resp.status === 422) {
                setTimeout(() => {
                    // getPaymentURL()
                    setLoading(false)
                }, 1000)
            }
            else {
                const error = resp.message
                popNotification(error, 2000, "Error", "danger")
            }

            setLoading(false)
        } catch (err) {
            if (err.response.status === 422) {
                // warranty is already accepted
                // getPaymentURL()
                if (closeModal) closeModal()
                setLoading(false)
            }

            popNotification(err.response.data.message, "Error")
            setLoading(false)
        }
    }

    return (
        <div className='flex items-center justify-center flex-col w-full mx-auto px-6 h-full'>
            {!secret && (
                <>
                    <h2 className="text-2xl font-bold">Warranty Created! {`ID#${warranty.id}`}</h2>
                    <span className="text-gray-300/90">Your warranty has been saved!</span>

                    <div className="flex flex-col items-center justify-center w-full mt-8 h-auto text-center">
                        {warranty.draft && <span className="text-xl font-semibold"> ✅ Your warranty is in draft, we will assess this and accept it soon!</span>}
                    </div>

                    {/* <PaymentForm /> */}
                    <div className="flex flex-col w-full mt-8 text-black dark:text-gray-100">
                        <div className="flex justify-between w-full items-center ">
                            <h2 className="text-2xl font-semibold">Policy information</h2>
                        </div>
                        <div className="grid grid-cols-3 w-full mt-4 ">
                            <span className="">Policy ID: </span>
                            <span className="">{warranty.id}</span>
                        </div>
                        <div className="grid grid-cols-3 w-full mt-4 ">
                            <span className="">Valid From: </span>
                            <span className="">{warranty.valid_from}</span>
                        </div>
                        <div className="grid grid-cols-3 w-full mt-4 ">
                            <span className="">Valid To: </span>
                            <span className="">{warranty.valid_to}</span>
                        </div>
                    </div>
                    <div className="flex flex-row justify-end text-right w-full pt-14">
                        <PaymentModal
                            warranty={warranty}
                            buttonOnClick={onAccept}
                            onComplete={() => {
                                handleClick('success')
                            }}
                            buttonText='Accept & Pay' />
                        <Button
                            className="mx-2"
                            type="button"
                            size="sm"
                            loading={loading}
                            onClick={handleClick}
                        >
                            View Warranty
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}