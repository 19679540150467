import React, { useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { StripePaymentForm } from './Payment'
import { apiGetStripePK } from 'services/AuthService'
import { popNotification } from 'views/warranties/CreateWarranty'

const StripePaymentElement = ({
    payment_intent,
    return_url = `${window.location.origin}/completion`
}) => {
    const [stripePK, setStripePK] = React.useState(null)

    const getStripePK = async () => {
        const response = await apiGetStripePK()
        if (response && response.status === 200) {
            const stripe_pk = response.data.stripe_publishable_key
            if (!stripe_pk) {
                popNotification('Something went wrong. Please try again', 2500, 'Error')
                return
            }
            setStripePK(stripe_pk)
        }
    }

    useEffect(() => {
        getStripePK()
    }, [])

    const stripePromise = loadStripe(stripePK)


    return (
        <Elements stripe={stripePromise} options={{
            clientSecret: payment_intent,
        }}>
            <StripePaymentForm return_url={return_url} />
        </Elements>
    )
}

export default StripePaymentElement
