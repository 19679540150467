import axios from 'axios'
import BaseService from './BaseService'

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then((response) => {
                    resolve(response)
                })
                .catch((errors) => {
                    reject(errors)
                })
        })
    },
    uploadFile(url, file) {
        return new Promise((resolve, reject) => {
            axios.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                },
            }).then((response) => {
                resolve(response)
            }
            ).catch((errors) => {
                reject(errors)
            })
        })
    }
}


export default ApiService
