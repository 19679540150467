import { ProductCard } from '../ProductCard'
import { QUESTIONS, ownerInfoKeys, vehicleInfoKeys } from '../constants/steps'
import { Button } from 'components/ui'
import { useDispatch, useSelector } from 'react-redux'
import { setWarranty, setWarrantyData } from '../store/dataSlice'
import { apiCreateCustomer, apiCreateWarranty } from 'services/LoveWarrantyServices'
import { initialOwnerInfo } from '../constants/intialValues'
import { popNotification } from '..'
import { formatCurrency } from 'utils/helpers'

const NEXT_STEP = 4

export const Review = ({ setStep }) => {
    const dispatch = useDispatch()
    const { ownerInfo, productData, vehicleInfo, warrantyData, searchUser } = useSelector((state) => state.createWarranty.data)

    const createWarranty = async (updatedWarrantyData) => {
        let warranty

        if (!warrantyData.customer_id) warranty = await apiCreateWarranty(updatedWarrantyData)
        else warranty = await apiCreateWarranty(warrantyData)

        // create warranty
        if (warranty && warranty.status === 201) {
            dispatch(setWarranty(warranty.data))
            return {
                error: false,
                message: 'Draft saved',
                data: warranty.data
            }
        }
    }

    const saveDraft = async () => {
        try {
            // check if customer_id is set in warrantyData
            if (warrantyData.customer_id) {
                // create warranty
                const warranty = await createWarranty(warrantyData)
                return warranty
            }

            // create customer
            if (ownerInfo && ownerInfo !== initialOwnerInfo) {
                // create customer
                const owner = await apiCreateCustomer(ownerInfo)

                if (owner && owner.data) {
                    dispatch(setWarrantyData({
                        ...warrantyData,
                        customer_id: owner.data.id
                    }))

                    let updatedWarrantyData = {
                        ...warrantyData,
                        customer_id: owner.data.id
                    }


                    // create warranty
                    const warranty = await createWarranty(updatedWarrantyData)
                    return warranty
                }
            }
        } catch (err) {
            return {
                error: true,
                message: err.response.data.message
            }
        }
    }

    const handleContinue = async () => {
        const resp = await saveDraft()

        if (resp.error) {
            popNotification(resp.message)
            return
        }
        setStep(NEXT_STEP)
    }

    return (
        <div className='flex items-center justify-center flex-col w-full mx-auto px-6 h-full'>
            <h2 className="text-2xl font-bold">Thanks {ownerInfo.first_name}, we're almost there</h2>
            <span className="text-gray-300/90 w-2/3 mt-2 text-center">Please check if all the information is correct. If you're happy with the details and your plan, please continue to payment processing</span>

            <div className="flex flex-col w-full mt-8">
                <ProductCard single={true} product={productData} />
            </div>

            <div className="flex flex-col w-full mt-8">
                <div className="flex justify-between w-full items-center">
                    <h2 className="text-2xl font-semibold">Policy information</h2>
                </div>

                <div className="grid grid-cols-3 w-full mt-4 text-black dark:text-gray-100">
                    <span className="">Excess:</span>
                    <span className="">{formatCurrency(productData.excess)}</span>
                </div>
                <div className="grid grid-cols-3 w-full mt-4 text-black dark:text-gray-100">
                    <span className="">Policy start date:</span>
                    <span className="">{warrantyData.valid_from}</span>
                </div>
                <div className="grid grid-cols-3 w-full mt-4 text-black dark:text-gray-100">
                    <span className="">Expiration date:
                    </span>
                    {/* productData.duration_months(int) + warrantyData.valid_from(date string) */}
                    <span className="">{
                        new Date(new Date(warrantyData.valid_from).setMonth(new Date(warrantyData.valid_from).getMonth() + productData.duration_months)).toISOString().split('T')[0]
                    }</span>
                </div>
                <div className="grid grid-cols-3 w-full mt-4 text-black dark:text-gray-100">
                    <span className="">Expiration Mileage:
                    </span>
                    <span className="">{productData.max_milage ? `${productData.max_milage} Miles` : "N/A"}</span>
                </div>
            </div>

            <div className="flex flex-col w-full mt-8">
                <div className="flex justify-between w-full items-center">
                    <h2 className="text-2xl font-semibold">Vehicle information</h2>
                    <button
                        className="text-white bg-slate-500 rounded-full text-center px-4 py-2 hover:cursor-pointer"
                        type='button'
                        onClick={() => setStep(0)}>
                        Edit
                    </button>
                </div>
                {Object.entries(vehicleInfo).map((item, index) => (
                    vehicleInfoKeys[item[0]] && (
                        <div className="grid grid-cols-3 w-full mt-4 text-black dark:text-gray-100 " key={index}>
                            <span className="">{vehicleInfoKeys[item[0]]}: </span>
                            <span className="">{item[1] ? item[1] : '-'}</span>
                        </div>
                    )
                ))}
            </div>

            <div className="flex flex-col w-full mt-8">
                <div className="flex justify-between w-full items-center">
                    <h2 className="text-2xl font-semibold">Owner information</h2>
                    {!searchUser.found && (
                        <button
                            className="text-white bg-slate-500 rounded-full text-center px-4 py-2 hover:cursor-pointer"
                            type='button'
                            onClick={() => setStep(1)}>
                            Edit
                        </button>
                    )}
                </div>
                {Object.entries(ownerInfo).map((item, index) => (
                    ownerInfoKeys[item[0]] && (
                        <div className="grid grid-cols-3 w-full mt-4 text-black dark:text-gray-100 " key={index}>
                            <span className="">{ownerInfoKeys[item[0]]}: </span>
                            <span className="">{item[1] ? item[1] : '-'}</span>
                        </div>
                    )
                ))}
            </div>

            <div className="flex flex-col w-full mt-8">
                <div className="flex justify-between w-full items-center">
                    <h2 className="text-2xl font-semibold">Vehicle Confirmation information</h2>
                    <button
                        className="text-white bg-slate-500 rounded-full text-center px-4 py-2 hover:cursor-pointer"
                        type='button'
                        onClick={() => setStep(0)}>
                        Edit
                    </button>
                </div>

                {QUESTIONS.map((item, index) => (
                    <div className="grid grid-cols-4 w-full mt-4 text-black dark:text-gray-100  gap-5" key={index}>
                        <span className="w-full col-span-3">{item.question}</span>
                        <span className="capitalize flex gap-2 items-center">
                            <span className={`w-2 h-2 rounded-full ${item.expected === 'yes' ? 'bg-green-500' : 'bg-custom-300'}`}></span>
                            {item.expected}
                        </span>
                    </div>
                ))}
            </div>

            <div className="text-right w-full pt-16 flex justify-between">
                <Button
                    className="mx-2"
                    type="button"
                    onClick={() => { setStep(2) }}
                >
                    Previous
                </Button>

                <Button
                    onClick={handleContinue}
                    type="submit"
                    variant="solid">
                    Create Warranty
                </Button>
            </div>
        </div>
    )
}

