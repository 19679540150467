export const getYear = (regYear) => {
    return new Date(regYear).getFullYear()
}

export const randomAvatar = (first, last = "") => {
    // const names = ['Mahalia Jackson', 'Mary Baker', 'Alicia Dickerson', 'Lucy Stone']
    // const random = names[Math.floor(Math.random() * names.length)].split(' ').join('%20')

    // return `https://source.boringavatars.com/beam/120/${random}?colors=F4C790,F2977E,BA6868`
    return `https://ui-avatars.com/api/?name=${first}${last ? `%20${last}` : ''}&background=random&length=2&bold=true`
}

export const formatCurrency = (amount) => {
    if (!amount) return '£0.00'
    
    // add pound sign in front of amount and format to 2 decimal places and add commas
    return `£${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}