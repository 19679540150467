import React, { useEffect, useState } from 'react'
import { Button, FormContainer, FormItem, Input, Select } from 'components/ui'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { ownerInfoKeys, titleOptions } from '../constants/steps'
import { useDispatch, useSelector } from 'react-redux'
import { setOwnerInfo, setSearchedUser, setWarrantyData } from '../store/dataSlice'
import { apiGetCustomer, apiSearch } from 'services/LoveWarrantyServices'
import { popNotification } from '..'
import { initialOwnerInfo } from '../constants/intialValues'
import { PostcodeLookup } from "@ideal-postcodes/postcode-lookup"

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title Required'),
    first_name: Yup.string().required('First Name Required'),
    last_name: Yup.string().required('Last Name Required'),
    email: Yup.string().email('Invalid email').required('Email Required'),
    emailConfirm: Yup.string().oneOf([Yup.ref('email'), null], 'Emails must match').required('Confirm Email Required'),
    phone: Yup.string().required('Please enter your phone number'),
    post_code: Yup.string().required('Please enter your post_code'),
    address_1: Yup.string().required('Please enter your address'),
    city: Yup.string().required('Please enter your city'),
    county: Yup.string().required('Please enter your county'),
    // hearAboutUs: Yup.string().required('Please select how you heard about us'),
})

export const OwnerInfo = ({ setStep }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { ownerInfo, searchUser, warrantyData } = useSelector((state) => state.createWarranty.data)
    const [manualAddress, setManualAddress] = useState(false)
    const [lookupAddresses, setLookupAddresses] = useState([])

    const [userState, setUserState] = useState(ownerInfo)

    useEffect(() => {
        if (searchUser.searched && !searchUser.found) {
            try {
                // https://docs.ideal-postcodes.co.uk/docs/postcode-lookup/custom-elements
                const controller = PostcodeLookup.setup({
                    apiKey: process.env.REACT_IDEAL_POSTCODE_API_KEY ?? "ak_ltxbo7k1TSd5Ep49wdSuTof1kIsSP",
                    context: "#lookup_field",
                    outputFields: {
                        line_1: "#first_line",
                        line_2: "#second_line",
                        line_3: "#third_line",
                        post_town: "#post_town",
                        postcode: "#postcode",
                    }
                })
                console.log(controller)
            } catch (error) {
                console.log(error)
            }
        }
    }, [searchUser.searched, searchUser.found])

    const onLookUp = async (postcode) => {
        const apiKey = process.env.REACT_APP_GOOGLE_API_KEY
        if (!apiKey) {
            console.error('Google API key not found')
            popNotification('Google API key not found', 5000, 'Error', 'error')
            return
        }

        // Adjust the radius parameter as needed (default is 50000 meters or 50 kilometers)
        const radius = 50000

        // Constructing the API URL with additional parameters for radius and address components
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${apiKey}&components=country:GB&radius=${radius}`
        setLoading(true)

        // Making a GET request to the Google Geocoding API
        await fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then(data => {
                if (data.results.length > 0) {
                    const addresses = data.results.map(result => {
                        // Extracting necessary details from the result
                        let formattedAddress = result.formatted_address
                        const addressComponents = result.address_components
                        const uniqueKey = result.place_id // Using place_id as a unique key

                        // Extracting city and county from address components
                        let city = ''
                        let county = ''
                        let post_code = ''
                        addressComponents.forEach(component => {
                            if (component.types.includes('locality') || component.types.includes('postal_town')) {
                                city = component.long_name
                            } else if (component.types.includes('administrative_area_level_2')) {
                                county = component.long_name
                            }

                            if (component.types.includes('postal_code')) {
                                post_code = component.long_name
                            }
                        })

                        // remove post code from address
                        formattedAddress = formattedAddress.replace(post_code, '')

                        // Creating an object with necessary details
                        return {
                            uniqueKey,
                            formattedAddress,
                            city,
                            county,
                            // You can add more details as needed
                        }
                    })
                    setLookupAddresses(addresses)
                    console.log('Addresses:', addresses)
                } else {
                    console.log('No results found for the given postcode.')
                    popNotification('No results found for the given postcode.', 5000, 'Oops!', 'warning')
                }
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error)
                setLoading(false)
            })
    }

    const checkForExistingUser = async (values) => {
        const { email } = values
        const resp = await apiSearch(email)

        if (resp && resp.status === 200) {
            const { data } = resp.data

            if (data.length === 0) {
                setTimeout(() => {
                    dispatch(setOwnerInfo(values))

                    dispatch(setWarrantyData({
                        ...warrantyData,
                        customer_id: null,
                    }))

                    setStep(2)
                }, 400)

                return
            }

            // loop through the data and get all with type = user
            const users = data.filter(item => item.type === "user")
            const possibleTarget = users[0].data.id

            if (possibleTarget) {
                const user = await apiGetCustomer(possibleTarget)

                if (user && user.status === 200) {
                    const { data } = user

                    dispatch(
                        setSearchedUser({
                            searched: true,
                            found: true,
                        })
                    )

                    dispatch(setOwnerInfo(data))
                    setUserState(data)

                    dispatch(setWarrantyData({
                        ...warrantyData,
                        customer_id: data.id,
                    }))

                    popNotification(`A user with email ${email} already exists`, 5000, "User found", "warning")
                    return
                }
            }

            dispatch(
                setSearchedUser({
                    searched: true,
                    found: false,
                })
            )
        }
    }

    const onUserFormSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        const { email } = values
        const resp = await apiSearch(email)

        if (resp && resp.status === 200) {
            const { data } = resp.data

            if (data.length === 0) {
                setSubmitting(false)

                dispatch(
                    setSearchedUser({
                        searched: true,
                        found: false,
                    })
                )

                setUserState({
                    ...userState,
                    email: email,
                })

                popNotification(`No user found with email ${email}`, 2500, "User not found")
                return
            }

            // loop through the data and get all with type = user
            const users = data.filter(item => item.type === "user")
            const possibleTarget = users[0].data.id

            if (possibleTarget) {
                const user = await apiGetCustomer(possibleTarget)

                if (user && user.status === 200) {
                    const { data } = user
                    setUserState({
                        ...userState,
                        emailConfirm: data.email,
                        ...data
                    })

                    dispatch(
                        setSearchedUser({
                            searched: true,
                            found: true,
                        })
                    )

                    dispatch(setOwnerInfo(data))

                    dispatch(setWarrantyData({
                        ...warrantyData,
                        customer_id: data.id,
                    }))

                    setSubmitting(false)
                    popNotification(`User found with email ${email}`, 2500, "User found", "success")
                    return
                }
            }

            setSubmitting(false)
            dispatch(
                setSearchedUser({
                    searched: true,
                    found: false,
                })
            )

            setUserState({
                ...userState,
                email: email,
            })

            popNotification(`No user found with email ${email}`, 2500, "User not found")
        }
    }

    const renderUserDetailsForm = (initialData) => {
        return (
            <Formik
                initialValues={{
                    ...initialData,
                    address_lookup: '',
                }}
                validationSchema={validationSchema}
                onSubmit={
                    (values, { setSubmitting }) => {
                        setSubmitting(true)
                        checkForExistingUser(values)
                    }
                }
            >
                {({ values, touched, errors, isSubmitting, setFieldValue }) => {
                    return (
                        <Form className="mt-6 grid gap-y-6 gap-x-4 grid-cols-1 w-full">
                            <FormContainer>
                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="Title"
                                        invalid={
                                            errors.title &&
                                            touched.title &&
                                            !ownerInfo.title
                                        }
                                        errorMessage={errors.title}
                                    >
                                        <Field name="title">
                                            {({ field, form }) => (
                                                <Select
                                                    placeholder="Please select title"
                                                    field={field}
                                                    form={form}
                                                    options={titleOptions}
                                                    value={titleOptions.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.title
                                                    )}
                                                    onChange={(option) =>
                                                        form.setFieldValue(
                                                            field.name,
                                                            option.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="First Name"
                                        invalid={
                                            errors.first_name &&
                                            touched.first_name &&
                                            !ownerInfo.first_name
                                        }
                                        errorMessage={errors.first_name}
                                    >
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            name="first_name"
                                            placeholder="First Name"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="Last Name"
                                        invalid={
                                            errors.last_name &&
                                            touched.last_name &&
                                            !ownerInfo.last_name
                                        }
                                        errorMessage={errors.last_name}
                                    >
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            name="last_name"
                                            placeholder="Last Name"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="Company Name"
                                    >
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            name="company"
                                            placeholder="Company Name"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="Email"
                                        invalid={
                                            errors.email &&
                                            touched.email &&
                                            !ownerInfo.email
                                        }
                                        errorMessage={errors.email}
                                    >
                                        <Field
                                            type="email"
                                            autoComplete="off"
                                            name="email"
                                            placeholder="Email"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="Confirm Email"
                                        invalid={
                                            errors.emailConfirm &&
                                            touched.emailConfirm
                                        }
                                        errorMessage={errors.emailConfirm}
                                    >
                                        <Field
                                            type="email"
                                            autoComplete="off"
                                            name="emailConfirm"
                                            placeholder="Confirm Email"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="Phone Number"
                                        invalid={
                                            errors.phone &&
                                            touched.phone &&
                                            !ownerInfo.phone
                                        }
                                        errorMessage={errors.phone}
                                    >
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            name="phone"
                                            placeholder="Phone Number"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">

                                    <FormItem
                                        label="Postcode"
                                        invalid={
                                            errors.post_code &&
                                            touched.post_code &&
                                            !ownerInfo.post_code
                                        }
                                        errorMessage={errors.post_code}
                                    >
                                        <div id="lookup_field" className='flex flex-col w-full gap-3'></div>

                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            name="post_code"
                                            placeholder="Postcode"
                                            id="postcode"
                                            component={Input}
                                            className="rounded-full w-full lg:w-2/3 hidden"
                                        />
                                    </FormItem>
                                </div>

                                {/* dropdown for showing all found addresses */}
                                {lookupAddresses.length > 0 && (
                                    <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                        <FormItem
                                            label="Select Address"
                                        >
                                            <Field name="address_lookup">
                                                {({ field, form }) => (
                                                    <Select
                                                        field={field}
                                                        form={form}
                                                        placeholder="Please select address"
                                                        options={lookupAddresses.map((address) => ({
                                                            label: address.formattedAddress,
                                                            value: address.uniqueKey,
                                                        }))}
                                                        value={field.value ? lookupAddresses.find((address) => {
                                                            if (address.uniqueKey === field.value) {
                                                                return address.label
                                                            }
                                                        }) : ''}
                                                        onChange={(option) => {
                                                            console.log(option, field)
                                                            form.setFieldValue(field.name, option.value)

                                                            const selectedAddress = lookupAddresses.find(
                                                                (address) => address.uniqueKey === option.value
                                                            )
                                                            if (selectedAddress) {
                                                                setFieldValue('address_1', selectedAddress.formattedAddress)
                                                                setFieldValue('city', selectedAddress.city)
                                                                setFieldValue('county', selectedAddress.county)
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormItem>
                                    </div>
                                )}

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="Address Line 1"
                                        invalid={
                                            errors.address_1 &&
                                            touched.address_1 &&
                                            !ownerInfo.address_1
                                        }
                                        errorMessage={errors.address_1}
                                    >
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            id="first_line"
                                            name="address_1"
                                            placeholder="Address Line 1"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="Address Line 2"
                                    >
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            id="second_line"
                                            name="address_2"
                                            placeholder="Address Line 2"
                                            component={Input}

                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="City"
                                        invalid={
                                            errors.city &&
                                            touched.city &&
                                            !ownerInfo.city
                                        }
                                        errorMessage={errors.city}
                                    >
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            id="post_town"
                                            name="city"
                                            placeholder="City"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="flex items-start lg:items-center flex-col lg:flex-row">
                                    <FormItem
                                        label="County"
                                        invalid={
                                            errors.county &&
                                            touched.county &&
                                            !ownerInfo.county
                                        }
                                        errorMessage={errors.county}
                                    >
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            name="county"
                                            placeholder="County"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                </div>

                                <div className="mt-4 text-right w-full flex justify-between">
                                    <Button
                                        className="mx-2"
                                        type="button"
                                        onClick={() => { setStep(0) }}
                                    >
                                        Previous
                                    </Button>

                                    <Button
                                        type="submit"
                                        loading={isSubmitting}
                                        variant="solid">
                                        Next
                                    </Button>
                                </div>
                            </FormContainer>
                        </Form>
                    )
                }}
            </Formik>
        )
    }

    const renderFindUserForm = () => {
        return (
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email('Invalid email address')
                        .required('Email is required'),
                })}
                onSubmit={onUserFormSubmit}
            >
                {({ errors, touched, isSubmitting }) => {
                    return (
                        <Form className="mt-6 grid gap-y-6 gap-x-4 h-full grid-cols-1 w-full">
                            <FormContainer className="flex flex-col gap-4 w-full h-full">
                                <div className="flex items-center md:items-start lg:items-start w-full justify-between gap-2">
                                    <FormItem
                                        label="Search by Email"
                                        invalid={
                                            errors.email &&
                                            touched.email
                                        }
                                        errorMessage={errors.email}
                                    >
                                        <Field
                                            type="email"
                                            autoComplete="off"
                                            name="email"
                                            placeholder="Enter email address"
                                            component={Input}
                                            className="rounded-full w-full"
                                        />
                                    </FormItem>
                                    <Button
                                        type="submit"
                                        color="custom-100"
                                        loading={isSubmitting}
                                        variant="solid"
                                        className="w-fit !px-4"
                                    >
                                        <i className="fas fa-arrow-right"></i>
                                    </Button>
                                </div>
                                <div className="mt-4 text-right w-full flex flex-wrap lg:flex-row gap-2 justify-between">
                                    <Button
                                        type="button"
                                        onClick={() => { setStep(0) }}
                                    >
                                        Back
                                    </Button>

                                    <div className="flex gap-2">
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                setUserState(initialOwnerInfo)
                                                dispatch(setOwnerInfo(initialOwnerInfo))


                                                dispatch(
                                                    setSearchedUser({
                                                        searched: true,
                                                        found: false,
                                                    })
                                                )
                                            }}
                                            loading={isSubmitting}
                                            variant="solid">
                                            Add new
                                        </Button>
                                    </div>
                                </div>
                            </FormContainer>
                        </Form>
                    )
                }}
            </Formik>
        )

    }

    const renderUserDetails = (userState) => {
        return (
            <div className="flex flex-col w-full mt-8">
                {Object.entries(userState).map((item, index) => (
                    ownerInfoKeys[item[0]] && (
                        <div className="grid grid-cols-3 w-full mt-4 text-black dark:text-gray-100 " key={index}>
                            <span className="">{ownerInfoKeys[item[0]]}: </span>
                            <span className="">{item[1] ? item[1] : '-'}</span>
                        </div>
                    )
                ))}

                {/* button to correct data */}
                <div
                    className="flex items-center w-full mt-4"
                >
                    <span
                        className="dark:text-white cursor-pointer font-medium mb-4"
                        onClick={() => {
                            dispatch(
                                setSearchedUser({
                                    searched: true,
                                    found: false,
                                })
                            )

                            setUserState(initialOwnerInfo)
                            dispatch(setOwnerInfo(initialOwnerInfo))

                            dispatch(setWarrantyData({
                                ...warrantyData,
                                customer_id: null,
                            }))
                        }}
                    >
                        Not the correct person? <span className="text-custom-300 font-medium" >Click here to enter manually</span>
                    </span>
                </div>


                <div className="mt-4 text-right w-full flex justify-between">
                    <Button
                        className="mx-2"
                        type="button"
                        onClick={() => { setStep(0) }}
                    >
                        Previous
                    </Button>

                    <Button
                        type="button"
                        variant="solid"
                        onClick={() => { setStep(2) }}
                    >
                        Next
                    </Button>
                </div>
            </div>
        )
    }


    return (
        <div className='flex text-left flex-col w-full  px-6 h-full'>
            <h2 className="text-2xl font-bold">Owner Information</h2>
            <span className="text-gray-300/70">Please complete all of the following information</span>

            {!searchUser.searched && renderFindUserForm()} {/* render find user form */}
            {(searchUser.searched && !searchUser.found) && renderUserDetailsForm(userState)} {/* render user details form if not user found */}
            {(searchUser.searched && searchUser.found) && renderUserDetails(userState)} {/* render user details form if user found */}
        </div>
    )
}