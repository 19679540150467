import { Button } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { apiCreateVehicle } from 'services/LoveWarrantyServices'
import { useDispatch, useSelector } from 'react-redux'
import { setVehicleInfo, setWarrantyData } from '../store/dataSlice'
import { popNotification } from '..'

export const VehicleSearchForm = ({
    reg = "",
}) => {
    const [registrationNumber, setRegistrationNumber] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const warrantyData = useSelector((state) => state.createWarranty.data.warrantyData)

    useEffect(() => {
        if (reg) {
            setRegistrationNumber(reg)
            handleSearch(reg)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reg])

    const handleSearch = async (regNo = registrationNumber) => {
        setLoading(true)
        try {
            const resp = await apiCreateVehicle({
                "vrn": regNo,
            })

            setLoading(false)

            if (resp && resp.status === 201) {
                dispatch(setVehicleInfo(resp.data))
                dispatch(setWarrantyData({ ...warrantyData, vehicle_id: resp.data.id }))
            } else {
                alert(resp.message)
            }
        }
        catch (err) {
            setLoading(false)
            if (err.response && err.response.data && err.response.data.message) {
                return popNotification(err.response.data.message)
            }
            popNotification("Something went wrong")
        }
    }

    return (
        <div className="my-auto min-w-[550px] w-full h-full flex flex-col items-center justify-center">
            <h2 className="text-2xl font-bold">Let's get started!</h2>
            <span className="text-gray-400">Enter registration number to start creating a warranty</span>

            <div className="mt-6 w-full flex flex-col gap-2 items-center justify-center">
                <input
                    type="text"
                    className="w-3/4 rounded-full px-4 py-2 dark:bg-custom-200 text-black dark:text-white"
                    placeholder="Enter registration number"
                    defaultValue={registrationNumber}
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                />
                <Button className="mt-4"
                    onClick={() => {
                        handleSearch()
                    }}
                    loading={loading}
                    color="red-600"
                    disabled={registrationNumber.trim().length < 6}
                    variant="solid"
                    shape="circle" icon={<BiSearch />}>
                    Search
                </Button>
            </div>
        </div>
    )
}